import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActivationRateChart',
  props: ['data', 'total'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    setTimeout(() => {
      // this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      let width = this.$refs.chart.offsetWidth;
      let height = this.$refs.chart.offsetHeight;
      this.$nextTick(() => {
        this.chart = new F2.Chart({
          width,
          height,
          id: 'container',
          pixelRatio: window.devicePixelRatio
        });
        this.chart.source(this.data);
        this.chart.coord('polar', {
          transposed: true,
          radius: 0.85,
          innerRadius: 0.65
        });
        this.chart.axis(false);
        this.chart.tooltip(false);
        this.chart.legend({
          align: 'center',
          marker: {
            symbol: 'square'
          }
        });
        this.chart.guide().text({
          position: ['50%', '53%'],
          content: '总任务数\n' + this.total,
          style: {
            fontSize: 12,
            color: '#333'
          }
        });
        this.chart.interval().position('const*cnt').adjust('stack').color('type', ['#4f77aa', '#CCC']);
        this.chart.pieLabel({
          sidePadding: 80,
          activeShape: true,
          label1: function label1(data, color) {
            return {
              fill: color,
              text: data.cnt,
              fontWeight: 'bold'
            };
          }
        });
        this.chart.render();
      });
    }
  }
};