import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import Selector from '@/components/Selector';
import ActivationRateChart from '@/components/ActivationRateChart';
import { provinceFind, cityFind, activateRateDetail, activateRateMonthDetail } from '@/api/wxwork';
import dayjs from 'dayjs';
export default {
  components: {
    [_Tab.name]: _Tab,
    [_Tabs.name]: _Tabs,
    [_Icon.name]: _Icon,
    [_Tag.name]: _Tag,
    [_Empty.name]: _Empty,
    Selector,
    ActivationRateChart
  },
  data() {
    return {
      tabs: [{
        title: '全部',
        key: 0
      }, {
        title: '待激活',
        key: 1
      }, {
        title: '已激活',
        key: 2
      }],
      provinceList: [],
      cityList: [],
      typeList: [{
        label: '不限',
        value: ''
      }, {
        label: '新增',
        value: 1
      }, {
        label: '取消',
        value: 2
      }, {
        label: '原计划',
        value: 3
      }],
      formData: {
        province: '',
        city: '',
        isActive: 0,
        type: '',
        week: 1
      },
      isWeek: false,
      listData: [],
      activationRateData: [],
      total: 0
    };
  },
  mounted() {
    document.title = '激活率详情';
    const {
      week,
      total,
      activated,
      noActivated,
      isWeek
    } = this.$route.query;
    this.isWeek = isWeek + '' === 'true';
    this.activationRateData = [{
      const: 'const',
      type: '已经激活任务',
      cnt: parseInt(activated)
    }, {
      const: 'const',
      type: '待激活任务',
      cnt: parseInt(noActivated)
    }];
    this.formData.week = week;
    this.total = total;
    this.$refs.chart.initChart();
    this.getData();
    this.getProvince();
  },
  methods: {
    getData() {
      let obj = JSON.parse(JSON.stringify(this.formData));
      obj.isActive = obj.isActive || null;
      let handle = this.isWeek ? activateRateDetail : activateRateMonthDetail;
      handle(obj).then(res => {
        if (!res.data || !Array.isArray(res.data)) return;
        res.data.map(item => {
          item.updateDate = item.updateDate ? dayjs(item.updateDate).format('YYYY-MM-DD') : '';
        });
        this.listData = res.data;
      });
    },
    getProvince() {
      provinceFind().then(res => {
        this.provinceList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList.unshift({
          label: '不限',
          value: ''
        });
      });
    },
    getCity() {
      this.formData.city = '';
      this.cityList = [];
      cityFind({
        province: this.formData.province
      }).then(res => {
        this.cityList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList.unshift({
          label: '不限',
          value: ''
        });
        this.getData();
      });
    }
  }
};